<template>
  <div>
    <div style="border-bottom: 1px solid #f1f3f4">
      <div class="lgTop">
        <span class="lgTop_logo">
          <img style="margin: 14px 25px 0px 0px" src="./assets/img/logo1.png" />
        </span>
        <div class="qrcode-box">
          <!-- <a href="javascript:void(0)" class="lgDownBtn app-download"
          >系统操作手册</a
        > -->
          <!-- <p class="show-qrcode" style="display: none">
          <img style="width: 258px; height: 258px" />
        </p> -->
        </div>
      </div>
    </div>

    <div class="fromsty">
      <div>
        <el-steps :active="active">
          <el-step title="验证码验证" icon="el-icon-key"></el-step>
          <el-step title="重置密码" icon="el-icon-refresh"></el-step>
          <el-step title="去登录" icon="el-icon-check"></el-step>
        </el-steps>
        <div class="from-sty">
          <div v-if="active == 1">
            <el-form
              ref="fromData"
              label-width="0px"
              :model="from_Data"
              :rules="rules"
            >
              <el-form-item prop="mobile">
                <el-input
                  class="input1"
                  placeholder="请输入手机号"
                  prefix-icon="el-icon-user"
                  v-model="from_Data.mobile"
                ></el-input>
              </el-form-item>

              <div class="nlg_intBoxs">
                <el-form-item
                  prop="verify"
                  style="width: 250px"
                  :rules="[{ required: true, message: '请输入验证码' }]"
                >
                  <el-input
                    style="flex: 1"
                    class="input1"
                    placeholder="请输入验证码"
                    prefix-icon="el-icon-lock"
                    v-model="from_Data.verify"
                  ></el-input>
                </el-form-item>
                <el-button @click="getCode" class="stygetCode" type="primary">{{
                  countText
                }}</el-button>
              </div>
            </el-form>
          </div>
          <div v-else-if="active == 2">
            <el-form
              ref="fromData2"
              label-width="0px"
              :model="from_Data2"
              :rules="rules2"
            >
              <el-form-item
                prop="department_id"
                :rules="[{ required: true, message: '请选择责任部门' }]"
              >
                <el-select
                  class="input1"
                  v-model="from_Data2.department_id"
                  placeholder="请选择责任部门"
                >
                  <el-option
                    v-for="item in personageData"
                    :key="item.department_id"
                    :label="item.department_name"
                    :value="item.department_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="pwd">
                <el-input
                  class="input1"
                  placeholder="请输入密码"
                  v-model="from_Data2.pwd"
                  show-password
                ></el-input>
              </el-form-item>

              <el-form-item prop="checkPass">
                <el-input
                  class="input1"
                  placeholder="请再次输入密码"
                  v-model="from_Data2.checkPass"
                  show-password
                ></el-input>
              </el-form-item>
            </el-form>
          </div>

          <!-- <div v-else-if="active == 3"> -->
          <el-button type="primary" @click="submit" class="submitsty">{{
            ["", "提交", "修改密码"][active]
          }}</el-button>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ForgetPwdVerifySend,
  indexForgetPwd,
  indexcheckSmsVerify,
} from "./assets/request/api";
export default {
  data() {
    let phoneRuel = (rule, value, callback) => {
      if (!this.$tools.verify_phone(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.from_Data2.checkPass !== "") {
          this.$refs.fromData2.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.from_Data2.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      active: 1,
      show: true,
      count: "",
      countText: "点击发送",
      timer: null,
      from_Data: {
        verify: "",
        mobile: "",
      },
      personageData: [],
      rules: {
        mobile: [
          { validator: phoneRuel, trigger: "blur" },
          { required: true, message: "请输入用户手机号" },
        ],
      },
      from_Data2: {
        pwd: "",
        checkPass: "",
        department_id: "",
      },
      rules2: {
        pwd: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },

  methods: {
    getCode() {
      const TIME_COUNT = 60;
      let mobile = this.from_Data.mobile;
      console.log(this.$tools.verify_phone(mobile));

      if (!this.$tools.verify_phone(mobile)) {
        this.$message({
          message: "请输入正确的手机号码",
          type: "warning",
        });
        return;
      }
      if (!this.timer) {
        ForgetPwdVerifySend({ mobile }).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "短信发送成功",
              type: "success",
            });
            this.count = TIME_COUNT;

            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                this.countText = this.count + "S";
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
                this.countText = "点击发送";
              }
            }, 1000);
          }
        });
      } else {
        this.$message({
          message: "正在发送验证码，请稍等",
          type: "warning",
        });
      }
    },
    submit() {
      let { active, from_Data, from_Data2 } = this;
      let formName = active == 1 ? "fromData" : "fromData2";
      let requestURL = active == 1 ? indexcheckSmsVerify : indexForgetPwd;
      let requestData = active == 1 ? from_Data : from_Data2;
      if (active == 2) {
        requestData = {
          pwd: from_Data2.pwd,
          mobile: from_Data.mobile,
          department_id: from_Data2.department_id,
        };
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          requestURL(requestData).then((res) => {
            console.log(res);
            let that = this;

            if (res.code == 200 || res.code == 0) {
              if (active == 1) {
                this.$message({
                  message: "验证码验证成功",
                  type: "success",
                });
                this.active = 2;
                if (res.data.length > 0) {
                  let personageData = res.data;
                  this.from_Data2.department_id =
                    personageData[0].department_id;
                  this.personageData = personageData;
                }
              } else if (active == 2) {
                this.$message({
                  message: "密码重置成功，跳转登录页",
                  type: "success",
                });
                this.$tools.Clear_information();
                setTimeout(function () {
                  that.$router.replace({ path: "/login" });
                }, 1000);
              }
            } else {
              that.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.lgTop {
  height: 88px;
  width: 1085px;
  margin: 0 auto;
}
.input1 {
  width: 100%;
}
.stygetCode {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  margin-left: 20px;
  font-size: 15px;
}
.fromsty {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  & > div {
    width: 400px;
    padding: 30px;
    box-shadow: 0 0 30px #f1f3f4;
  }
}
.nlg_intBoxs {
  display: flex;
}
.from-sty {
  margin-top: 30px;
}
/deep/ .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
.submitsty {
  width: 100%;
  font-size: 18px;
}
</style>